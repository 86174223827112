import { render, staticRenderFns } from "./ProcessMasterData.vue?vue&type=template&id=18f92afa&"
import script from "./ProcessMasterData.vue?vue&type=script&lang=js&"
export * from "./ProcessMasterData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../infinity/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports